<template>
  <nav
    class="navbar fixed-top navbar-expand-lg navbar-light bg-light"
    style="padding-left: 100px; padding-right: 100px"
  >
    <div class="container-fluid">
      <router-link style="text-decoration: none" :to="{ name: 'Home' }" class="logo">
        <img src="/assets/logo-yellow.svg" alt="iAppraise" class="logo" />
        <span class="navbar-brand">iAppraise</span>
      </router-link>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarAnon"
        aria-controls="navbarAnon"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarAnon">
        <div class="navbar-nav justify-content-around w-100 text-center">
          <router-link :to="{ name: 'AboutUs' }" class="nav-item nav-link">About Us</router-link>
          <router-link :to="{ name: 'HelpCenter' }" class="nav-item nav-link">Help Center</router-link>
          <router-link :to="{ name: 'ContactUs' }" class="nav-item nav-link">Contact Us</router-link>
          <a href="tel:0418263166" class="nav-item nav-link">
            <img src="/assets/img/icon-phone.svg" class="icon" /> 0418 263 166
          </a>
          <a href="tel:0468469180" class="nav-item nav-link">
            <img src="/assets/img/icon-phone.svg" class="icon" /> 0468 469 180
          </a>
          <router-link :to="{ name: 'Login' }" class="buttons buttons-yellow sign-in mt-4 mt-lg-0">Log in</router-link>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import "bootstrap";

export default {
  name: "AnonHeader"
};
</script>

<style lang="scss" scoped>
@import "@/scss/base.scss";
@import "@/scss/buttons.scss";
@import "@/scss/_variables.scss";

.navbar {
  background-color: rgba(236, 236, 236, 0.95);

  .nav-item {
    color: #000 !important;
    font-weight: 500;
    padding-top: 15px;
    font-size: 16px;
    font-family: "sf_pro_displaysemibold", sans-serif;
  }
}

.sign-in {
  padding: 10px 30px;
  font-size: 18px;
  border-radius: 3px;

  @media (min-width: 1024px) {
    display: inline-block;
  }
}

.logo {
  min-height: 65px;
  min-width: 65px;
}
.navbar-brand {
  font-size: 24px;
  line-height: 32px;
  font-family: "sf_pro_displaysemibold", sans-serif;
  color: #000;
  text-decoration: none;
  vertical-align: middle;
  margin-left: 12px;
}

.router-link-active:not(.logo) {
  border-bottom: 3px solid #ffe608;
  cursor: default;
}
</style>
