<template>
  <div>
    <AnonHeader />
    <div style="margin-top: 81px">
      <router-view />
    </div>
    <Footer />
  </div>
</template>

<script>
import Footer from "@/components/Footers/Footer.vue";
import AnonHeader from "@/components/Headers/AnonHeader.vue";

export default {
  name: "AnonLayout",
  components: { Footer, AnonHeader }
};
</script>

<style lang="scss" scoped>
@import "@/scss/base.scss";
@import "@/scss/_variables.scss";
</style>
