<template>
  <div class="container pt-5 footer justify-content-between no-print">
    <div class="row">
      <div class="col-3">
        <router-link style="text-decoration: none" :to="{ name: 'Home' }" class="mt-0">
          <img style="margin-right: 10px" src="/assets/logo-yellow.svg" alt="iAppraise" />
          <span class="title">iAppraise</span>
        </router-link>
      </div>
      <div class="col-3">
        <p class="title">Quick Links</p>
      </div>
      <div class="col-3">
        <p class="title">Information</p>
      </div>
      <div class="col-3 text-center">
        <span class="built-by">Built by </span>
        <img
          src="/assets/img/kiratech_logo_white_bg.svg"
          alt="kiratech_logo"
          style="max-height: 40px"
          class="img-fluid"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-3">
        <div class="copyright mt-3">
          <p class="mb-2">© iAppraise, 2019</p>
          <p>ACN 617 815 139 XPhase Pty Ltd</p>
        </div>
      </div>
      <div class="col-3">
        <router-link :to="{ name: 'Home' }">Home</router-link>
        <router-link :to="{ name: 'AboutUs' }">About Us</router-link>
        <router-link :to="{ name: 'BookDemo' }">Book Demo</router-link>
        <router-link :to="{ name: 'ContactUs' }">Contact Us</router-link>
      </div>
      <div class="col-3">
        <router-link to="/privacy-policy">Privacy Policy </router-link>
        <router-link to="/terms-and-conditions">Terms and Conditions </router-link>
        <router-link to="/refund-policy">Cancellation and Refund Policy </router-link>
        <router-link to="/data-security-policy">Data Security Policy </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer"
};
</script>

<style lang="scss" scoped>
@import "~bootstrap/dist/css/bootstrap.min.css";
@import "@/scss/base.scss";
@import "@/scss/_variables.scss";

.footer {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: black;
  font: normal 16px $FONT_SF_PRO_DISPLAY_REGULAR;

  .title {
    font-weight: bold;
    font-size: 24px;
    margin-bottom: 0;
  }

  .built-by {
    font-size: 12px;
  }

  .copyright {
    color: #9ca0a5;
    font-weight: normal;
    font-size: 14px;
  }

  a {
    color: $UNSELECTED_MENU_COLOR;
    text-decoration: none;
    display: flex;
    font-size: 16px;
    cursor: pointer;
    margin-top: $PADDING_HALF;
    font-weight: bold;

    &.router-link-exact-active {
      color: $SELECTED_MENU_COLOR;
    }
  }
}
</style>
